<template>
  <v-container
    class="px-10"
    fluid
  >
    <v-row
      justify="space-between"
    >
      <v-col 
        cols="4"
        class="pl-0 pr-2 pt-0"
      >
        <base-material-card
          color="primary"
          icon="mdi-folder-network-outline"
          inline
          class="ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Diretórios
            </div>
          </template>

          <v-btn
            color="success"
            elevation="1"
            class="my-5"
            relative
            text
            medium
            @click="$router.push('grupos/historico');"
          >
            <v-icon 
              left 
              size="30px">
              mdi-plus-circle
            </v-icon>
            NOVO GRUPO
          </v-btn>

          <v-row 
            class="my-4"
            align="center"
            justify="center" 
            v-if="loadingTreeView"
          >
            <v-progress-circular indeterminate width="2"/>
          </v-row>
          <v-treeview
            v-else
            :items="directories"
            :active.sync="active"
            :open.sync="openTabs"
            activatable
            item-key="id"
            open-on-click
            :multiple-active="false"
            :transition="true"
          >
            <template v-slot:prepend="{ item, open, leaf }">
              <v-icon 
                v-if="leaf" 
                :key="item.id+directoriesWithFiles[item.id]">
                {{ directoriesWithFiles[item.id] == true 
                  ? 'mdi-check-bold' 
                  : 'mdi-folder' }}
              </v-icon>
              <v-icon v-else>
                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
              </v-icon>
            </template>
            <template v-slot:label="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="text-truncate">
                    {{ item.name }}
                  </div>
                </template>
                <span>{{ item.name }}</span>
              </v-tooltip>
            </template>
          </v-treeview>

        </base-material-card>
      </v-col>

      <v-col 
        cols="8"
        class="px-0 py-0"
      >
        <base-material-card
          color="primary"
          icon="mdi-file-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Arquivos
              <template v-if="selectedDirectory">
                <v-icon 
                  color="primary" 
                  class="mx-1 mb-1">
                  mdi-arrow-right-thick
                </v-icon>
                {{ selectedDirectory.dateFormatted }}
                <v-icon 
                  color="primary" 
                  class="mx-1 mb-1">
                  mdi-arrow-right-thick
                </v-icon>
                {{ selectedDirectory.grupo.nome }}
              </template>
            </div>
          </template>

          <v-row 
            class="mx-auto my-8" 
            align="center" 
            no-gutters>

            <v-col 
              cols="12" 
              v-if="!selectedDirectory">
              <v-alert
                dense 
                outlined 
                type="info" 
                color="blue" 
                class="mb-0 py-1 px-1"
              >
                Selecione um diretório ao lado para poder realizar os envios
              </v-alert>
            </v-col>

            <v-col 
              v-else
              cols="12">
              <div class="dropbox">
                <input
                  type="file" 
                  multiple 
                  class="input-file"
                  @change="filesChange($event);"
                >
                <v-row 
                  justify="center" 
                  align="center" 
                  class="py-4">
                  <v-icon 
                    color="primary" 
                    size="96">
                    mdi-cloud-upload-outline
                  </v-icon>
                </v-row>
                <p class="pb-4 text-center display-2">
                  Clique para adicionar ou arraste e solte os arquivos
                </p>
              </div>
            </v-col>

          </v-row>

          <general-progress-bars
            class="mt-11 mb-11"
            :items="uploadProgressData"
            prefixText="Enviando"
            suffixProperty="name"
            :includeHashOnText="false"
            :truncateText="true"
          />

          <arquivos-dados-upload-queue 
            :files.sync="files"
            :uploadProgressData.sync="uploadProgressData"
            @rechargeUploadedFiles="getArquivosFromSelectedGroup()"
            :selectedDirectory="selectedDirectory"
          />

          <general-progress-bars
            class="mt-8 mb-8"
            :items="downloadProgressData"
            prefixText="Baixando Arquivo"
          />

          <arquivos-dados-uploaded-files-list
            v-if="selectedDirectory"
            :items.sync="items"
            :downloadProgressData.sync="downloadProgressData"
            :loading="loading"
            :selectedDirectory="selectedDirectory"
            @rechargeUploadedFiles="getArquivosFromSelectedGroup()"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import dateFormatterMixins from '@/mixins/dateFormatterMixins';
import GruposArquivosService from '../../../services/GruposArquivosService';
import FilesService from '../../../services/FilesService';
import generateRandomString from '../../../utils/generateRandomString';

export default {
  name: 'ArquivosDadosIndex',

  mixins: [dateFormatterMixins],

  components: {
    GeneralProgressBars: () => import("@/components/general/GeneralProgressBars.vue"),
    ArquivosDadosUploadQueue: () => import('@/components/dados/arquivos-dados/ArquivosDadosUploadQueue.vue'),
    ArquivosDadosUploadedFilesList: () => import('@/components/dados/arquivos-dados/ArquivosDadosUploadedFilesList.vue'),
  },

  data: () => ({
    active: [],
    openTabs: [],
    directories: [],
    directoriesWithFiles: {},
    items: [],
    gruposArquivos: [],
    files: [],
    loading: false,
    loadingTreeView: true,
    downloadProgressData: {},
    uploadProgressData: {}
  }),

  async mounted() {
    await this.fetchGrupos();
    this.mountItemsDirectory();
  },

  computed: {
    selectedDirectory() {
      if (!this.active.length) return null;

      const [ano, mes, grupoId] = this.active[0].split('-');
      const grupo = this.gruposArquivos.find(g => g.id == grupoId);

      if (!grupo) return null;

      return {
        ano,
        mes,
        grupoId,
        grupo,
        dateFormatted: this.formatMonth(`${ano}-${mes}`)
      }
    },
    uploadInProgress() {
      return Object.values(this.uploadProgressData).length > 0;
    }
  },

  methods: {
    fetchGrupos() {
      return GruposArquivosService.getAllActive()
        .then(res => {
          this.gruposArquivos = res.data;

          this.gruposArquivos.forEach((grupo) => {
            grupo.arquivos.forEach((arquivo) => {
              const { ano, mes } = arquivo;
              const key = `${ano}-${String(mes).padStart(2, '0')}-${grupo.id}`;
              this.directoriesWithFiles[key] = true;
            });
          });
        }).catch(err => {
          console.error(err);
          this.$toast.error("Erro ao recuperar Grupos de Arquivos.", "", { position: "topRight" });
        });
    },
    mountItemsDirectory() {
      const currentYear = dayjs().format('YYYY');
      const currentMonth = dayjs().format('YYYY-MM')

      const years = Array(3).fill().map((_, i) => currentYear - i);
      const months = Array(12).fill()
        .map((_, i) => String(i + 1).padStart(2, '0'));

      this.directories = years.map(ano => ({
        id: ano,
        name: ano,
        disabled: false,
        children: months.map(mes => ({
          id: `${ano}-${mes}`,
          name: mes,
          children: this.gruposArquivos.map(grupo => ({
            id: `${ano}-${mes}-${grupo.id}`,
            name: grupo.nome
          }))
        }))
      }));

      this.loadingTreeView = false;
      this.openTabs = [currentYear, currentMonth];
    },
    getArquivosFromSelectedGroup() {
      if (!this.selectedDirectory) return; 

      this.items = [];
      this.loading = true;

      const { ano, mes, grupoId } = this.selectedDirectory;

      FilesService.getByAnoMesGrupo(ano, mes, grupoId)
        .then(res => {
          const { data } = res;
          this.items = data.map((arquivo) => ({
            id: arquivo.id,
            nome: arquivo.arquivo,
            size: arquivo.tamanho,
            enviado_por: arquivo.usuario.name,
            created: arquivo.created_at,
            usuario_id: arquivo.usuario_id
          }));

          const key = `${ano}-${mes}-${grupoId}`;
          this.$set(this.directoriesWithFiles, key, this.items.length > 0);
        }).catch(err => {
          console.error(err);
          this.$toast.error(`Erro ao recuperar arquivos.`, "", { position: "topRight" });
        })
        .finally(() => this.loading = false);
    },
    filesChange(event) {
      const fileList = event.target.files;

      if (!fileList.length) return;

      for (const file of fileList) {
        this.files.push({
          id: generateRandomString(30),
          name: file.name,
          size: file.size,
          fileObj: file
        })
      }

      event.target.value = '';
    }
  },

  watch: {
    active() {
      this.getArquivosFromSelectedGroup();
    },
    uploadInProgress() {
      for (const directory of this.directories) {
        directory.disabled = this.uploadInProgress;
      }
    }
  }
}
</script>

<style>
.v-alert__icon {
  align-self: center;
}

.first-v-col-diretorios {
  min-width: 195px !important;
}

@media screen and (max-width: 826px) {
  .break-point-custom-arquivos-dados {
    max-width: 100% !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    margin-left: 0px !important;
  }
}

.dropbox {
  outline: 2px dashed grey;
  outline-offset: -3px;
  padding: 10px 10px;
  min-height: 200px;
  position: relative;
  cursor: pointer;
}

.input-file {
  z-index: 111;
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
</style>